@import "https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap";

:root {
	--theme--dark: #1c3051ff;
	--theme-primary--light: #405665ff;
	--theme-primary: #111e30ff;
	--theme-secondary: #c46b79ff;
	--theme-secondary--dark: color-mix(in srgb, #c46b79ff, black);
}

html {
	scroll-padding: 120px 0 0;
}
* {
	box-sizing: border-box;
}

ul[class],
ol[class] {
	padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	min-height: 100vh;
	text-rendering: optimizespeed;
	color: white;
	line-height: 1.5;
	font-size: var(--font-size--default);
	font-family: PT Serif, serif;
	position: relative;
	background-color: var(--theme-primary);
}

ul[class],
ol[class] {
	margin-top: 0;
	list-style: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

a:hover {
	color: var(--theme-dark);
	text-decoration: none;
}

img {
	max-width: 100%;
	display: block;
}

input,
button,
textarea,
select {
	font: inherit;
}

@media (prefers-reduced-motion: reduce) {
	* {
		scroll-behavior: auto !important;
		transition-duration: 0.01ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
	}
}

html {
	scroll-behavior: smooth;
}

a {
	color: var(--theme-dark);
	font-weight: bold;
	text-decoration: none;
}

p {
	margin-bottom: 1rem;
}

table {
	width: 100%;
	margin-bottom: 1.5rem;
}

table td {
	font-size: var(--font-size--default);
	padding: 0.25rem 0.5rem;
}

table tbody tr {
	background-color: #fff;
}

table tbody tr:hover {
	background-color: #e3ecf6 !important;
}

table tr:not(:last-of-type) td {
	border-bottom: 1px solid #d0e0ef;
}

table th {
	color: var(--theme-dark);
	font-size: var(--font-size--small);
	text-transform: uppercase;
	padding: 0.25rem 0.5rem;
}

:root {
	--font-size--smaller: 0.9rem;
	--font-size--small: 1rem;
	--font-size--default: 1.4rem;
	--font-size--large: 1.8rem;
	--font-size--larger: 2rem;
	--font-size--h1: 2.4rem;
	--font-size--h2: 2rem;
	--font-size--h3: 1.8rem;
	--font-size--h4: 1.4rem;
	font-size: 62.5%;
}

@media screen and (min-width: 769px) {
	:root {
		--font-size--smaller: 1.4rem;
		--font-size--small: 1.6rem;
		--font-size--default: 1.75rem;
		--font-size--large: 3rem;
		--font-size--larger: 3.5rem;
		--font-size--h1: 5.25rem;
		--font-size--h2: 3rem;
		--font-size--h3: 2.25rem;
		--font-size--h4: 2rem;
	}
}

@media screen and (min-width: 1441px) {
	:root {
		--font-size--smaller: 1.4rem;
		--font-size--small: 2rem;
		--font-size--default: 2.25rem;
		--font-size--large: 3rem;
		--font-size--larger: 3.5rem;
		--font-size--h1: 6rem;
		--font-size--h2: 4rem;
		--font-size--h3: 3.25rem;
		--font-size--h4: 2.5rem;
	}
}

a {
	color: var(--theme-dark);
	font-weight: 600;
	font-size: var(--font-size--default);
	font-family: PT Serif, serif;
}

a:hover {
	color: var(--theme-dark);
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 2rem;
	font-weight: 600;
}

p {
	font-size: var(--font-size--default);
}

h1 {
	font-size: var(--font-size--h1);
}

h2 {
	font-size: var(--font-size--h2);
}

h3 {
	font-size: var(--font-size--h3);
}

h4 {
	font-size: var(--font-size--h4);
}

h5 {
	font-size: 1.75rem;
}

h6 {
	font-size: 1.25rem;
}

p {
	margin-bottom: 1.5rem;
	line-height: 1.75;
}

.lead {
	font-size: calc(var(--font-size--default) * 1.25);
	max-width: 900px;
	margin-block: 2rem;
	background-color: #74ced9;
	color: var(--theme-primary);
	padding: 1rem 2rem;
	border-radius: 4px;
}

@media screen and (min-width: 769px) {
	.lead {
		font-size: calc(var(--font-size--default) * 1.5);
		margin-bottom: 5rem;
	}
}

.lead a,
.lead span {
	font-size: var(--font-size--larger);
}

@media screen and (min-width: 1025px) {
	ol,
	ul {
		font-size: var(--font-size--default);
	}
}

ol li,
ul li {
	margin-bottom: 0.5rem;
	line-height: 1.75;
}

p + ul,
p + ol {
	margin-top: -0.5rem;
}

.footnote {
	font-size: var(--font-size--small);

	border: 4px;
	padding: 1rem;
	font-style: italic;
	box-shadow: 0 0.063em 0.313em #00000012, 0 0.438em 1.063em #0000001a;
}

.btn {
	font-size: calc(var(--font-size--default) * 0.9);
	border-radius: 4px;
	padding: 1rem 2rem;
	font-weight: 600;
	display: inline-block;
	text-align: center;
}

.btn--sm {
	font-size: calc(var(--font-size--default) * 0.7);
	border-radius: 4px;
	padding: 0.5rem 1rem;
	border: 0;
}
.btn--primary {
	background-color: var(--theme--dark);
	text-shadow: 1px 1px 1px #00000080;
	color: #fff;
	border-color: var(--theme-primary);
}

.btn--primary:hover {
	background-color: var(--theme-primary--light);
	border-color: var(--theme-primary--light);
	color: #fff;
	text-shadow: none;
}

.btn--secondary {
	background-color: var(--theme-secondary--dark);
	text-shadow: 1px 1px 1px #00000080;
	color: #fff;
	border-color: var(--theme-secondary--dark);
}

.btn--secondary:hover {
	background-color: var(--theme-secondary);
	border-color: var(--theme-secondary);
	color: #fff;
}

.btn--outline {
	color: var(--theme-dark);
	border: 1px solid var(--theme-secondary--dark);
	border-color: var(--theme-primary);
	background-color: #0000;
}

.btn--outline:hover {
	background-color: var(--theme-primary--light);
	border-color: var(--theme-primary--light);
}

#event {
	height: 100%;
	position: relative;
}

.quick-actions {
	width: 320px;
	z-index: 1;

	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	flex-direction: column;
	padding: 1rem;
	font-size: 0.8rem;
	display: flex;
	position: fixed;
	top: 10%;
	right: 0;
	box-shadow: 0 0.063em 0.313em #00000012, 0 0.438em 1.063em #0000001a;
}

@media screen and (min-width: 1441px) {
	.quick-actions {
		width: 400px;
	}
}

.quick-actions h2 {
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
}

.quick-actions .sign-up {
	font-size: var(--font-size--default);
	background-color: var(--theme-dark);
	color: #fff;
	text-align: center;
	border-radius: 2px;
	margin-bottom: 1.5rem;
	padding: 0.5rem 1rem;
	font-weight: bold;
	display: inline-block;
}

.quick-actions .sign-up:hover {
	background-color: var(--theme-primary);
}

@media screen and (min-width: 769px) {
	.quick-actions .sign-up {
		margin-bottom: 0;
	}
}

.quick-actions .keep-informed {
	font-size: var(--font-size--default);
	background-color: var(--mid-gray);
	color: #fff;
	text-align: center;
	border-radius: 2px;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1rem;
	font-weight: bold;
	display: inline-block;
}

.quick-actions .keep-informed:hover {
	background-color: var(--theme-dark);
}

.quick-actions hr {
	color: var(--mid-gray);
}

.anchor {
	height: 40px;
	margin-top: -40px;
}

.print {
	display: none;
}

.skip-link {
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
}

.skip-link:focus {
	clip: auto;
	height: auto;
	overflow: visible;
	position: static !important;
}

.video-wrapper {
	background-color: #fff;
	border-radius: 4px;
	padding: 2rem;
}

.callout-text {
	color: #fff;
	background-color: var(--theme-primary);
	border-radius: 4px;
	padding: 0.25rem;
	font-weight: 600;
	display: inline-block;
}

strong {
	color: var(--theme-dark);
}

@keyframes pulse-red {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 #ff5252b3;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 14px #ff525200;
	}

	100% {
		transform: scale(0.99);
		box-shadow: 0 0 #ff525200;
	}
}

main > section {
	max-width: 90vw;
	margin: 0 auto;
	padding: 0.5rem 1rem;
}

@media screen and (min-width: 1400px) {
	main > section {
		max-width: 1200px;
	}
}

@media screen and (min-width: 767px) {
	main > section {
		grid-column-gap: 2rem;
		grid-row-gap: 0;
		grid-template-columns: repeat(12, 1fr);
		padding: 1.5rem 0;
		display: grid;
	}
}

main > section h2 {
	grid-column: span 12;
	margin-bottom: 2rem;
}

.abi-footer {
	font-size: var(--font-size--default);
	background: center/ 210px repeat url(/assets/pipes.png);
	color: #fff;
	justify-content: space-between;
	padding: 4rem 2rem;
	font-weight: 500;
}

@media screen and (min-width: 768px) {
	.abi-footer {
		padding-left: 2.5vw;
		padding-right: 2.5vw;
	}
}

@media screen and (min-width: 1441px) {
	.abi-footer {
		padding-left: 7.5vw;
		padding-right: 7.5vw;
	}
}

@media screen and (min-width: 769px) {
	.abi-footer {
		grid-template-columns: repeat(6, 1fr);
		gap: 1rem;
		display: grid;
	}
}

.abi-footer svg {
	margin-right: 8px;
}

.abi-footer a {
	color: #fff;
	font-size: var(--font-size--smaller);
	text-decoration: none;
	display: inline-block;
}

.abi-footer a:not(:last-of-type) {
	margin-right: 1rem;
}

.abi-footer a:hover {
	color: var(--theme-primary--secondary);
}

.abi-footer__info {
	grid-area: 2 / span 4;
	display: flex;
}

.abi-footer__address {
	font-size: var(--font-size--small);
	padding: 1.5rem 0;
}

.abi-footer__address ul {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
}

.abi-footer__address ul li,
.abi-footer__links li {
	margin-bottom: 0.25rem;
}

.abi-footer__social-media {
	flex-direction: column;
	grid-area: 2 / span 2;
	justify-content: center;
	align-items: flex-start;
	display: flex;
}

@media screen and (min-width: 769px) {
	.abi-footer__social-media {
		align-items: flex-end;
	}
}

.abi-footer__social-media div {
	text-align: right;
	border-bottom: 4px solid #fff;
	margin-bottom: 0.5rem;
	padding-bottom: 0.5rem;
}

.abi-footer__social-media nav {
	background-color: var(--theme-primary-darker);
	justify-content: flex-end;
	display: flex;
}

.abi-footer__social-media nav a {
	font-size: var(--font-size--large);
}

.abi-footer .help {
	color: var(--theme-dark);
	font-size: var(--font-size--smaller);
	text-transform: uppercase;

	border-radius: 4px;
	margin: 1rem 0;
	padding: 0.25rem 0.75rem;
}

.non-profit-statement {
	font-size: var(--font-size--smaller);
	color: #fff;
	text-shadow: 1px 1px 1px var(--a6);
	text-align: center;
}

.intro {
	grid-template-columns: 1fr;
	display: grid;
	position: relative;
	padding: 1rem 1rem;

	background: rgba(var(--theme-secondary), 0.8), url(/assets/pipes.webp);
}

@media screen and (min-width: 768px) {
	.intro {
		padding: 0;
		grid-template-columns: 2fr 1fr;
	}
}

.intro video {
	width: 100%;
	object-fit: cover;
}

.nav {
	width: 100%;
	z-index: 2;
	background-color: rgba(255, 255, 255, 0.95);
	border-bottom-right-radius: 4px;

	align-items: center;
	gap: 2rem;
	padding: 1rem 2rem;
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	/* box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); */
}

.nav svg {
	width: 50px;
	fill: var(--theme-dark);
}

.nav__abilogo a {
	align-items: center;
	gap: 1rem;
	display: flex;
	color: var(--theme-primary);
}

.nav__abilogo img {
	width: 160px;
}

.nav__button {
	background-color: #0000;
	border: 0;
}

@media screen and (min-width: 769px) {
	.nav__button {
		padding: 1rem 2rem;
	}
}

.nav__button:hover {
	background-color: var(--gray--light);
	cursor: pointer;
}

.nav__links {
	display: none;
}

.nav__links.show {
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	height: 100vh;
	width: 400px;
	background-color: #fff;
	flex-direction: column;
	align-items: flex-start;
	gap: 2rem;
	padding: 2rem;
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
}

.nav__links.show img {
	width: 150px;
	margin-block: 2rem;
}

.nav__links.show h2 {
	font-size: 2.5rem;
}

.nav__links.show a {
	font-size: 2.5rem;
	color: var(--theme-secondary--dark);
}

.nav__links.show a:hover {
	color: var(--theme-secondary--dark--hover);
}

.banner {
	background: center/contain no-repeat url("/assets/banner.png"), var(--theme-secondary--dark);
	margin-top: 80px;
	height: calc(0.26656 * 100vw);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 768px) {
	.banner {
		margin-top: 60px;
	}
}

.intro__date {
	font-size: var(--font-size--small);

	display: block;
}

.intro__buttons {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 2rem;
}
@media screen and (min-width: 768px) {
	.intro__buttons a {
		font-size: 2rem;
	}
}

h1 {
	font-size: var(--font-size--larger);
	font-weight: 600;
	margin-bottom: 0.5rem;
	/* sans serif */
}

.intro__event {
	background: center/ 210px repeat url(/assets/pipes.png);
	padding: 1rem 2rem 0.5rem 2rem;
	margin-bottom: 2rem;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.intro__eventDetails {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.intro__text {
	padding: 0 2rem;
}
.intro__text h1,
.intro__eventDetails {
	text-shadow: 1px 1px 2px var(--theme-primary);
}
.intro img,
#agenda img {
	display: none;
}
@media screen and (min-width: 768px) {
	body > section > section {
		padding: 3rem 4rem 3rem;
	}
	.intro img,
	#agenda img {
		display: block;
	}
}
.intro__text p {
	font-size: var(--font-size--h4);
}

ul li svg {
	box-sizing: border-box;
	line-height: 1.75;
	font-size: inherit;
	height: 0.75em;
	vertical-align: -0.125em;
	width: 0.75em;
	margin-right: 8px;
	list-style-type: none;
	display: inline-block;
	overflow: visible;
}

.abi-footer__address ul {
	margin-top: 0;
}

.abi-footer__address ul li {
	line-height: 1.25;
}

.abi-footer .abi-footer__address {
	font-style: normal;
}

.abi-footer svg {
	fill: #644b0c;
}

.partners__list {
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	gap: 2rem;
	padding: 1rem;
	display: flex;
	margin-bottom: 2rem;
	width: 100%;
}
.partners__list:not(:last-of-type) {
	padding-bottom: 2rem;
	margin-bottom: 2rem;
}

.partners__list > li {
	flex-basis: 180px;
	background-color: white;
	padding: 0.5rem 1rem;
	border-radius: 4px;
}

.partners__list > li span {
	display: none;
}
#partners {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}
#partners h3 {
	font-size: calc(var(--font-size--default) * 0.7);
	background-color: var(--theme-secondary);
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	color: white;
	display: inline-block;
	padding: 0.25rem 1rem;
	text-align: center;
	border-radius: 2px;
	margin-bottom: 1rem;
	font-family: sans-serif;
	font-weight: 400;
}
#sponsors {
	grid-column: span 2;
}

.sponsors__list {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	padding-top: 2rem;
}
.sponsors__list > div {
	flex-basis: 150px;
}
@media screen and (min-width: 768px) {
	.sponsors__list {
		justify-content: center;
		align-items: center;
		gap: 3rem;
		padding: 2rem 1rem 1rem 1rem;
		display: flex;
	}
	.sponsors__list > div {
		flex-basis: auto;
	}
}

.sponsors__list > li span {
	display: none;
}

video {
	object-fit: cover;
	height: 540px;
	display: block;
}

.more-content {
	padding: 0 2rem;
}
@media screen and (min-width: 821px) {
	.more-content {
		display: grid;
		padding: 0 4rem;
		grid-template-columns: 60% 1fr;
	}
}

@media screen and (min-width: 1200px) {
	.more-content {
		grid-template-columns: 80% 1fr;
	}
}

.more-content > section {
	grid-column: span 2;
}

.more-content > section:nth-of-type(5) {
	grid-column: span 2;
}

.more-content > section:nth-of-type(6) {
	/* background-color: var(--gray--light); */
	grid-column: span 2;
}

.more-content > section#hotel {
	background-color: var(--theme-secondary--dark);
	color: white;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
	grid-column: span 2;
	grid-template-columns: 1fr 1fr;
	padding: 0;
	display: grid;
}

@media screen and (min-width: 768px) {
	.more-content > section#hotel img {
		grid-column: span 1;
		height: 100%;
		object-fit: cover;
	}
}

.more-content > section#hotel address {
	margin-bottom: 1rem;
	font-weight: 600;
}

.more-content > section#hotel article {
	padding: 4rem 2rem;
}

/* @media screen and (max-width: 768px) {
	.more-content > section {
		grid-column: span 2;
	}
} */

h2 {
	font-size: calc(var(--font-size--default) * 1.5);
	border-bottom: 4px solid var(--theme-secondary);
	margin-bottom: 2rem;
	display: inline-block;
}

.callout {
	background-color: var(--theme--dark);
	color: #fff;
	font-size: var(--font-size--default);
	text-transform: uppercase;
	border-radius: 8px;
	margin-bottom: 2rem;
	padding: 2rem;
	font-family: sans-serif;
	font-weight: 600;
	display: inline-block;
}

.callout .callout-number {
	font-weight: 400;
	font-size: calc(var(--font-size--larger) * 1.5);
	display: block;
}

.round-img {
	border-radius: 50%;
	width: 115px;
	height: auto;
	overflow: hidden;
}

.speakers p {
	font-size: 2.5rem;
}
#agenda {
	grid-column: span 2;
	margin-bottom: 5rem;
}
#speakers {
	display: none;
	grid-column: span 2;
}

@media screen and (min-width: 768px) {
	#speakers {
		display: block;
	}
}

.events {
	position: relative;
}

@media screen and (min-width: 768px) {
	.events {
		display: grid;
		grid-template-columns: repeat(6, 300px);
		grid-gap: 2rem;
		overflow-x: auto;
	}
}

.event-day-mobile {
	display: block;
	font-size: 1.55rem;
	font-weight: 600;
	margin: 2rem 1rem;
	margin-bottom: -1.5rem;
}

@media screen and (min-width: 768px) {
	.event-day-mobile {
		display: none;
	}
}

.event-days {
	display: none;
	padding-inline-start: 2rem;
	font-weight: 600;
	grid-template-columns: repeat(6, 300px);
	border-radius: 4px;
	background: linear-gradient(90deg, hsla(339, 100%, 55%, 1) 0%, #743fba 75%, hsla(197, 100%, 64%, 1) 100%);
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
}
@media screen and (min-width: 768px) {
	.event-days {
		display: grid;
		grid-column: span 6;
		gap: 2rem;
	}
}

.event-date {
	font-size: 1.55rem;
	font-weight: 600;
	margin-bottom: 2rem;
	background-color: var(--theme-secondary--dark);

	color: white;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
	padding-inline: 2rem;
}
@media screen and (min-width: 768px) {
	.event-date {
		font-size: 1.75rem;

		padding-block: 1rem;
		padding-inline: 3rem;
		margin-inline: -2rem;
		z-index: 1;
	}
}

.event-speakers {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}
.event-sponsor {
	font-size: 1.15rem;

	/* color: white; */
	font-family: sans-serif;
	text-transform: uppercase;
	background-color: var(--gray--light);
	padding: 1rem;
	padding-left: 1.5rem;
	border-left: 4px solid var(--theme-secondary--dark);
	border-radius: 2px;
	font-weight: 600;
	margin-block: 1.5rem;
}

.event-title {
	font-size: 1.1rem;
	margin-bottom: 1rem;
}
.event-title .has-modal {
	color: var(--theme--dark);
	cursor: pointer;
	text-decoration: underline;
}
.event-title .has-modal:hover {
	color: var(--theme--dark--hover);
}
.event-time {
	font-size: 1.25rem;
	font-weight: 600;
	color: var(--theme-secondary--dark);

	text-transform: uppercase;
}

@media screen and (min-width: 821px) {
	.event-title {
		font-size: 1.75rem;
	}
	.event-time {
		font-size: 1.5rem;
	}
}

.event-speaker {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	flex-basis: 400px;
}

.event-speaker .round-img {
	flex-basis: 52px;
}
.event-speaker > div:nth-of-type(2) {
	flex-basis: 300px;
}
.event-speaker .speaker-name {
	font-size: 14px;
}
.event-cards {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}
.event-card {
	padding-bottom: 0.5rem;
	min-height: 60px;
	background-color: white;
	color: var(--theme--dark);
	border-left: 8px solid var(--theme-secondary);
	padding: 0.5rem 0.7rem 0.5rem 1.5rem;
	border-radius: 4px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.025);
}
.event-card:empty {
	display: none;
}
.event-card:not(:last-of-type) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.event-card__inner {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.agenda > div img {
	width: 200px;
}

#rates {
	grid-column: span 2;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}
#rates h2 {
	flex: 0 0 100%;
}
.rate {
	border: 1px solid var(--gray--light);
	border-radius: 4px;
	margin-bottom: 2rem;
	padding: 2rem 3rem;
	font-weight: 600;
}

@media screen and (min-width: 768px) {
	.rate {
		flex: 0 0 24%;
	}
}

.rate hr {
	border-color: var(--gray--light);
}

.rate h3 {
	font-size: calc(var(--font-size--default) * 0.8);
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
	background-color: var(--theme-secondary--dark);
	color: white;
	display: inline-block;
	padding: 0.25rem 1rem;
	border-radius: 4px;
	margin-bottom: 1rem;
	font-family: sans-serif;
	font-weight: 600;
}

.rate .price {
	color: var(--green);
	font-size: 2rem;
	font-weight: 600;
}

.rate .emphasis .price {
	flex-direction: column;
	font-size: 3.5rem;
	display: flex;
}

.rate .emphasis {
	margin-bottom: 1rem;
}
#rates p em {
	padding-inline: 2rem;
	display: block;
}

img[alt="Hogan Lovells"] {
	max-width: 100px;
	margin: 0 auto;
}

footer.abi-footer .help {
	color: var(--theme--dark);
}

footer .abi-footer__social-media nav svg {
	min-width: 25px;
	fill: #644b0c;
}

.menu__header {
	align-items: center;
	display: flex;
}

.close {
	align-items: center;
	gap: 0.25rem;
	font-family: sans-serif;
	font-size: 1.25rem;
	font-weight: 600;
	display: flex;
	position: absolute;
	top: 2.5rem;
	right: 1rem;
}

.intro__center--mobile {
	display: none;
}
/* .speakers {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	gap: 2rem;
} */
/* .glide__slides {
	flex-wrap: wrap !important;
} */
.speaker {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100px;
	flex-basis: 250px;
}
.speaker-name {
	font-size: var(--font-size--small);
	font-weight: 600;
	margin-bottom: 0;
}
.speaker-firm {
	font-size: var(--font-size--smaller);
}
@media screen and (max-width: 1024px) {
	.intro__center {
		display: none;
	}
	.intro__center--mobile {
		display: block;
	}

	.quad1 {
		min-height: 460px;
	}
	.intro__center {
		margin: 12rem 2rem 2rem 2rem;
		top: 0;
		left: 0;
		/* width: 280px; */
	}
	.agenda > div img {
		width: 130px;
	}
	.speakers p {
		font-size: var(--font-size--default);
	}

	.intro__center .intro__buttons {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		text-align: center;
	}
	.partners__list > li {
		flex: 0 0 150px;
	}

	.sponsors__list > li {
		flex: 0 0 150px;
	}

	.rate {
		padding: 1rem;
	}
	.rate .emphasis .price {
		font-size: 2rem;
	}
	.callout .callout-number {
		font-size: var(--font-size--large);
	}
	.intro__center .intro__buttons .btn {
		margin-bottom: 1rem;
	}

	.more-content > section#hotel article {
		grid-row: 2;
		grid-column: span 2;
	}
	.more-content > section#hotel img {
		grid-column: span 2;
	}

	/* section > .nav {
		width: auto;
	} */
}
.intro__date,
.intro__location {
	font-size: var(--font-size--default);
	display: flex;

	gap: 1rem;
	align-items: center;
}

.intro__date svg,
.intro__location svg {
	width: 20px;
}
@media screen and (min-width: 768px) {
	.intro__date svg,
	.intro__location svg {
		width: 30px;
	}
}

#hotel p {
	font-size: 1.75rem;
}

#hotel address {
	font-size: 1.75rem;
}

.agenda-nav {
	display: flex;
	justify-content: space-between;
	padding-bottom: 1rem;
	gap: 2rem;
	z-index: 1;
	font-size: 1rem;
	margin-block: 1.5rem;
}

.agenda-nav div {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}
.agenda-nav h4 {
	margin: 0;
	font-size: 1.75rem;
}
.agenda-nav a {
	display: inline-block;
	font-size: 1.75rem;
	color: var(--theme-secondary--dark);
}

.agenda-nav a:not(:first-of-type) {
	padding-left: 1rem;
	border-left: 4px solid var(--theme-secondary--dark);
}

.sr-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
#policies h3 {
	font-size: 1.75rem;
}
#policies p {
	font-size: 1.5rem;
}
p {
	max-width: 800px;
}

/* #register-now {
	position: fixed;
	bottom: 3rem;
	right: 5rem;
	z-index: 2;
	transform: scale(1.5);
}
@media screen and (min-width: 768px) {
	#register-now {
		bottom: unset;
		top: 2rem;
		transform: scale(1);
	}
} */

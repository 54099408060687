@import "https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap";
:root {
  --theme--dark: #1c3051;
  --theme-primary--light: #405665;
  --theme-primary: #111e30;
  --theme-secondary: #c46b79;
  --theme-secondary--dark: #62363d;
}

html {
  scroll-padding: 120px 0 0;
}

* {
  box-sizing: border-box;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, p, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  color: #fff;
  line-height: 1.5;
  font-size: var(--font-size--default);
  background-color: var(--theme-primary);
  font-family: PT Serif, serif;
  position: relative;
}

ul[class], ol[class] {
  margin-top: 0;
  list-style: none;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

a:hover {
  color: var(--theme-dark);
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    -webkit-animation-duration: .01ms !important;
    animation-duration: .01ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--theme-dark);
  font-weight: bold;
  text-decoration: none;
}

p {
  margin-bottom: 1rem;
}

table {
  width: 100%;
  margin-bottom: 1.5rem;
}

table td {
  font-size: var(--font-size--default);
  padding: .25rem .5rem;
}

table tbody tr {
  background-color: #fff;
}

table tbody tr:hover {
  background-color: #e3ecf6 !important;
}

table tr:not(:last-of-type) td {
  border-bottom: 1px solid #d0e0ef;
}

table th {
  color: var(--theme-dark);
  font-size: var(--font-size--small);
  text-transform: uppercase;
  padding: .25rem .5rem;
}

:root {
  --font-size--smaller: .9rem;
  --font-size--small: 1rem;
  --font-size--default: 1.4rem;
  --font-size--large: 1.8rem;
  --font-size--larger: 2rem;
  --font-size--h1: 2.4rem;
  --font-size--h2: 2rem;
  --font-size--h3: 1.8rem;
  --font-size--h4: 1.4rem;
  font-size: 62.5%;
}

@media screen and (min-width: 769px) {
  :root {
    --font-size--smaller: 1.4rem;
    --font-size--small: 1.6rem;
    --font-size--default: 1.75rem;
    --font-size--large: 3rem;
    --font-size--larger: 3.5rem;
    --font-size--h1: 5.25rem;
    --font-size--h2: 3rem;
    --font-size--h3: 2.25rem;
    --font-size--h4: 2rem;
  }
}

@media screen and (min-width: 1441px) {
  :root {
    --font-size--smaller: 1.4rem;
    --font-size--small: 2rem;
    --font-size--default: 2.25rem;
    --font-size--large: 3rem;
    --font-size--larger: 3.5rem;
    --font-size--h1: 6rem;
    --font-size--h2: 4rem;
    --font-size--h3: 3.25rem;
    --font-size--h4: 2.5rem;
  }
}

a {
  color: var(--theme-dark);
  font-weight: 600;
  font-size: var(--font-size--default);
  font-family: PT Serif, serif;
}

a:hover {
  color: var(--theme-dark);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 2rem;
  font-weight: 600;
}

p {
  font-size: var(--font-size--default);
}

h1 {
  font-size: var(--font-size--h1);
}

h2 {
  font-size: var(--font-size--h2);
}

h3 {
  font-size: var(--font-size--h3);
}

h4 {
  font-size: var(--font-size--h4);
}

h5 {
  font-size: 1.75rem;
}

h6 {
  font-size: 1.25rem;
}

p {
  margin-bottom: 1.5rem;
  line-height: 1.75;
}

.lead {
  font-size: calc(var(--font-size--default) * 1.25);
  max-width: 900px;
  color: var(--theme-primary);
  background-color: #74ced9;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
}

@media screen and (min-width: 769px) {
  .lead {
    font-size: calc(var(--font-size--default) * 1.5);
    margin-bottom: 5rem;
  }
}

.lead a, .lead span {
  font-size: var(--font-size--larger);
}

@media screen and (min-width: 1025px) {
  ol, ul {
    font-size: var(--font-size--default);
  }
}

ol li, ul li {
  margin-bottom: .5rem;
  line-height: 1.75;
}

p + ul, p + ol {
  margin-top: -.5rem;
}

.footnote {
  font-size: var(--font-size--small);
  border: 4px;
  padding: 1rem;
  font-style: italic;
  box-shadow: 0 .063em .313em rgba(0, 0, 0, .07), 0 .438em 1.063em rgba(0, 0, 0, .1);
}

.btn {
  font-size: calc(var(--font-size--default) * .9);
  text-align: center;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-weight: 600;
  display: inline-block;
}

.btn--sm {
  font-size: calc(var(--font-size--default) * .7);
  border: 0;
  border-radius: 4px;
  padding: .5rem 1rem;
}

.btn--primary {
  background-color: var(--theme--dark);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
  color: #fff;
  border-color: var(--theme-primary);
}

.btn--primary:hover {
  background-color: var(--theme-primary--light);
  border-color: var(--theme-primary--light);
  color: #fff;
  text-shadow: none;
}

.btn--secondary {
  background-color: var(--theme-secondary--dark);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
  color: #fff;
  border-color: var(--theme-secondary--dark);
}

.btn--secondary:hover {
  background-color: var(--theme-secondary);
  border-color: var(--theme-secondary);
  color: #fff;
}

.btn--outline {
  color: var(--theme-dark);
  border: 1px solid var(--theme-secondary--dark);
  border-color: var(--theme-primary);
  background-color: rgba(0, 0, 0, 0);
}

.btn--outline:hover {
  background-color: var(--theme-primary--light);
  border-color: var(--theme-primary--light);
}

#event {
  height: 100%;
  position: relative;
}

.quick-actions {
  width: 320px;
  z-index: 1;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  flex-direction: column;
  padding: 1rem;
  font-size: .8rem;
  display: flex;
  position: fixed;
  top: 10%;
  right: 0;
  box-shadow: 0 .063em .313em rgba(0, 0, 0, .07), 0 .438em 1.063em rgba(0, 0, 0, .1);
}

@media screen and (min-width: 1441px) {
  .quick-actions {
    width: 400px;
  }
}

.quick-actions h2 {
  margin-bottom: .5rem;
  font-size: .9rem;
}

.quick-actions .sign-up {
  font-size: var(--font-size--default);
  background-color: var(--theme-dark);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: .5rem 1rem;
  font-weight: bold;
  display: inline-block;
}

.quick-actions .sign-up:hover {
  background-color: var(--theme-primary);
}

@media screen and (min-width: 769px) {
  .quick-actions .sign-up {
    margin-bottom: 0;
  }
}

.quick-actions .keep-informed {
  font-size: var(--font-size--default);
  background-color: var(--mid-gray);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  margin-bottom: .5rem;
  padding: .5rem 1rem;
  font-weight: bold;
  display: inline-block;
}

.quick-actions .keep-informed:hover {
  background-color: var(--theme-dark);
}

.quick-actions hr {
  color: var(--mid-gray);
}

.anchor {
  height: 40px;
  margin-top: -40px;
}

.print {
  display: none;
}

.skip-link {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
}

.skip-link:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static !important;
}

.video-wrapper {
  background-color: #fff;
  border-radius: 4px;
  padding: 2rem;
}

.callout-text {
  color: #fff;
  background-color: var(--theme-primary);
  border-radius: 4px;
  padding: .25rem;
  font-weight: 600;
  display: inline-block;
}

strong {
  color: var(--theme-dark);
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-transform: scale(.99);
    transform: scale(.99);
    box-shadow: 0 0 rgba(255, 82, 82, .7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 14px rgba(255, 82, 82, 0);
  }

  100% {
    -webkit-transform: scale(.99);
    transform: scale(.99);
    box-shadow: 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-red {
  0% {
    -webkit-transform: scale(.99);
    transform: scale(.99);
    box-shadow: 0 0 rgba(255, 82, 82, .7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 14px rgba(255, 82, 82, 0);
  }

  100% {
    -webkit-transform: scale(.99);
    transform: scale(.99);
    box-shadow: 0 0 rgba(255, 82, 82, 0);
  }
}

main > section {
  max-width: 90vw;
  margin: 0 auto;
  padding: .5rem 1rem;
}

@media screen and (min-width: 1400px) {
  main > section {
    max-width: 1200px;
  }
}

@media screen and (min-width: 767px) {
  main > section {
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    padding: 1.5rem 0;
    display: grid;
  }
}

main > section h2 {
  grid-column: span 12;
  margin-bottom: 2rem;
}

.abi-footer {
  font-size: var(--font-size--default);
  color: #fff;
  background: url("pipes.39d40ead.png") center / 210px;
  justify-content: space-between;
  padding: 4rem 2rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .abi-footer {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
}

@media screen and (min-width: 1441px) {
  .abi-footer {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }
}

@media screen and (min-width: 769px) {
  .abi-footer {
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    display: grid;
  }
}

.abi-footer svg {
  margin-right: 8px;
}

.abi-footer a {
  color: #fff;
  font-size: var(--font-size--smaller);
  text-decoration: none;
  display: inline-block;
}

.abi-footer a:not(:last-of-type) {
  margin-right: 1rem;
}

.abi-footer a:hover {
  color: var(--theme-primary--secondary);
}

.abi-footer__info {
  grid-area: 2 / span 4;
  display: flex;
}

.abi-footer__address {
  font-size: var(--font-size--small);
  padding: 1.5rem 0;
}

.abi-footer__address ul {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.abi-footer__address ul li, .abi-footer__links li {
  margin-bottom: .25rem;
}

.abi-footer__social-media {
  flex-direction: column;
  grid-area: 2 / span 2;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media screen and (min-width: 769px) {
  .abi-footer__social-media {
    align-items: flex-end;
  }
}

.abi-footer__social-media div {
  text-align: right;
  border-bottom: 4px solid #fff;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

.abi-footer__social-media nav {
  background-color: var(--theme-primary-darker);
  justify-content: flex-end;
  display: flex;
}

.abi-footer__social-media nav a {
  font-size: var(--font-size--large);
}

.abi-footer .help {
  color: var(--theme-dark);
  font-size: var(--font-size--smaller);
  text-transform: uppercase;
  border-radius: 4px;
  margin: 1rem 0;
  padding: .25rem .75rem;
}

.non-profit-statement {
  font-size: var(--font-size--smaller);
  color: #fff;
  text-shadow: 1px 1px 1px var(--a6);
  text-align: center;
}

.intro {
  background: rgba(var(--theme-secondary), .8), url("pipes.900f2c6d.webp");
  grid-template-columns: 1fr;
  padding: 1rem;
  display: grid;
  position: relative;
}

@media screen and (min-width: 768px) {
  .intro {
    grid-template-columns: 2fr 1fr;
    padding: 0;
  }
}

.intro video {
  width: 100%;
  object-fit: cover;
}

.nav {
  width: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, .95);
  border-bottom-right-radius: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.nav svg {
  width: 50px;
  fill: var(--theme-dark);
}

.nav__abilogo a {
  color: var(--theme-primary);
  align-items: center;
  gap: 1rem;
  display: flex;
}

.nav__abilogo img {
  width: 160px;
}

.nav__button {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

@media screen and (min-width: 769px) {
  .nav__button {
    padding: 1rem 2rem;
  }
}

.nav__button:hover {
  background-color: var(--gray--light);
  cursor: pointer;
}

.nav__links {
  display: none;
}

.nav__links.show {
  height: 100vh;
  width: 400px;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .15);
}

.nav__links.show img {
  width: 150px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.nav__links.show h2 {
  font-size: 2.5rem;
}

.nav__links.show a {
  color: var(--theme-secondary--dark);
  font-size: 2.5rem;
}

.nav__links.show a:hover {
  color: var(--theme-secondary--dark--hover);
}

.banner {
  background: center / contain no-repeat url("banner.a1c372b1.png"), var(--theme-secondary--dark);
  height: 26.656vw;
  margin-top: 80px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

@media screen and (min-width: 768px) {
  .banner {
    margin-top: 60px;
  }
}

.intro__date {
  font-size: var(--font-size--small);
  display: block;
}

.intro__buttons {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  display: flex;
}

@media screen and (min-width: 768px) {
  .intro__buttons a {
    font-size: 2rem;
  }
}

h1 {
  font-size: var(--font-size--larger);
  margin-bottom: .5rem;
  font-weight: 600;
}

.intro__event {
  background: url("pipes.39d40ead.png") center / 210px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 2rem;
  padding: 1rem 2rem .5rem;
}

.intro__eventDetails {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.intro__text {
  padding: 0 2rem;
}

.intro__text h1, .intro__eventDetails {
  text-shadow: 1px 1px 2px var(--theme-primary);
}

.intro img, #agenda img {
  display: none;
}

@media screen and (min-width: 768px) {
  body > section > section {
    padding: 3rem 4rem;
  }

  .intro img, #agenda img {
    display: block;
  }
}

.intro__text p {
  font-size: var(--font-size--h4);
}

ul li svg {
  box-sizing: border-box;
  line-height: 1.75;
  font-size: inherit;
  height: .75em;
  vertical-align: -.125em;
  width: .75em;
  margin-right: 8px;
  list-style-type: none;
  display: inline-block;
  overflow: visible;
}

.abi-footer__address ul {
  margin-top: 0;
}

.abi-footer__address ul li {
  line-height: 1.25;
}

.abi-footer .abi-footer__address {
  font-style: normal;
}

.abi-footer svg {
  fill: #644b0c;
}

.partners__list {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
}

.partners__list:not(:last-of-type) {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.partners__list > li {
  background-color: #fff;
  border-radius: 4px;
  flex-basis: 180px;
  padding: .5rem 1rem;
}

.partners__list > li span {
  display: none;
}

#partners {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

#partners h3 {
  font-size: calc(var(--font-size--default) * .7);
  background-color: var(--theme-secondary);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .15);
  color: #fff;
  text-align: center;
  border-radius: 2px;
  margin-bottom: 1rem;
  padding: .25rem 1rem;
  font-family: sans-serif;
  font-weight: 400;
  display: inline-block;
}

#sponsors {
  grid-column: span 2;
}

.sponsors__list {
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 2rem;
  display: flex;
}

.sponsors__list > div {
  flex-basis: 150px;
}

@media screen and (min-width: 768px) {
  .sponsors__list {
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 2rem 1rem 1rem;
    display: flex;
  }

  .sponsors__list > div {
    flex-basis: auto;
  }
}

.sponsors__list > li span {
  display: none;
}

video {
  object-fit: cover;
  height: 540px;
  display: block;
}

.more-content {
  padding: 0 2rem;
}

@media screen and (min-width: 821px) {
  .more-content {
    grid-template-columns: 60% 1fr;
    padding: 0 4rem;
    display: grid;
  }
}

@media screen and (min-width: 1200px) {
  .more-content {
    grid-template-columns: 80% 1fr;
  }
}

.more-content > section, .more-content > section:nth-of-type(5), .more-content > section:nth-of-type(6) {
  grid-column: span 2;
}

.more-content > section#hotel {
  background-color: var(--theme-secondary--dark);
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
  grid-column: span 2;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  display: grid;
}

@media screen and (min-width: 768px) {
  .more-content > section#hotel img {
    height: 100%;
    object-fit: cover;
    grid-column: span 1;
  }
}

.more-content > section#hotel address {
  margin-bottom: 1rem;
  font-weight: 600;
}

.more-content > section#hotel article {
  padding: 4rem 2rem;
}

h2 {
  font-size: calc(var(--font-size--default) * 1.5);
  border-bottom: 4px solid var(--theme-secondary);
  margin-bottom: 2rem;
  display: inline-block;
}

.callout {
  background-color: var(--theme--dark);
  color: #fff;
  font-size: var(--font-size--default);
  text-transform: uppercase;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 2rem;
  font-family: sans-serif;
  font-weight: 600;
  display: inline-block;
}

.callout .callout-number {
  font-weight: 400;
  font-size: calc(var(--font-size--larger) * 1.5);
  display: block;
}

.round-img {
  width: 115px;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
}

.speakers p {
  font-size: 2.5rem;
}

#agenda {
  grid-column: span 2;
  margin-bottom: 5rem;
}

#speakers {
  grid-column: span 2;
  display: none;
}

@media screen and (min-width: 768px) {
  #speakers {
    display: block;
  }
}

.events {
  position: relative;
}

@media screen and (min-width: 768px) {
  .events {
    grid-gap: 2rem;
    grid-template-columns: repeat(6, 300px);
    display: grid;
    overflow-x: auto;
  }
}

.event-day-mobile {
  margin: 2rem 1rem -1.5rem;
  font-size: 1.55rem;
  font-weight: 600;
  display: block;
}

@media screen and (min-width: 768px) {
  .event-day-mobile {
    display: none;
  }
}

.event-days {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  background: linear-gradient(90deg, #ff1a6a 0%, #743fba 75%, #47cbff 100%);
  border-radius: 4px;
  grid-template-columns: repeat(6, 300px);
  font-weight: 600;
  display: none;
}

.event-days:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  padding-left: 2rem;
}

.event-days:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 2rem;
}

.event-days:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 2rem;
}

@media screen and (min-width: 768px) {
  .event-days {
    grid-column: span 6;
    gap: 2rem;
    display: grid;
  }
}

.event-date {
  background-color: var(--theme-secondary--dark);
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 1.55rem;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .event-date {
    z-index: 1;
    margin-left: -2rem;
    margin-right: -2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 1.75rem;
  }
}

.event-speakers {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.event-sponsor {
  text-transform: uppercase;
  background-color: var(--gray--light);
  padding: 1rem;
  border-left: 4px solid var(--theme-secondary--dark);
  border-radius: 2px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
  font-family: sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
}

.event-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.event-title .has-modal {
  color: var(--theme--dark);
  cursor: pointer;
  text-decoration: underline;
}

.event-title .has-modal:hover {
  color: var(--theme--dark--hover);
}

.event-time {
  color: var(--theme-secondary--dark);
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (min-width: 821px) {
  .event-title {
    font-size: 1.75rem;
  }

  .event-time {
    font-size: 1.5rem;
  }
}

.event-speaker {
  flex-basis: 400px;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.event-speaker .round-img {
  flex-basis: 52px;
}

.event-speaker > div:nth-of-type(2) {
  flex-basis: 300px;
}

.event-speaker .speaker-name {
  font-size: 14px;
}

.event-cards {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.event-card {
  min-height: 60px;
  color: var(--theme--dark);
  border-left: 8px solid var(--theme-secondary);
  background-color: #fff;
  border-radius: 4px;
  padding: .5rem .7rem .5rem 1.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .024);
}

.event-card:empty {
  display: none;
}

.event-card:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.event-card__inner {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.agenda > div img {
  width: 200px;
}

#rates {
  flex-wrap: wrap;
  grid-column: span 2;
  gap: 1rem;
  display: flex;
}

#rates h2 {
  flex: 0 0 100%;
}

.rate {
  border: 1px solid var(--gray--light);
  border-radius: 4px;
  margin-bottom: 2rem;
  padding: 2rem 3rem;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .rate {
    flex: 0 0 24%;
  }
}

.rate hr {
  border-color: var(--gray--light);
}

.rate h3 {
  font-size: calc(var(--font-size--default) * .8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
  background-color: var(--theme-secondary--dark);
  color: #fff;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: .25rem 1rem;
  font-family: sans-serif;
  font-weight: 600;
  display: inline-block;
}

.rate .price {
  color: var(--green);
  font-size: 2rem;
  font-weight: 600;
}

.rate .emphasis .price {
  flex-direction: column;
  font-size: 3.5rem;
  display: flex;
}

.rate .emphasis {
  margin-bottom: 1rem;
}

#rates p em {
  padding-left: 2rem;
  padding-right: 2rem;
  display: block;
}

img[alt="Hogan Lovells"] {
  max-width: 100px;
  margin: 0 auto;
}

footer.abi-footer .help {
  color: var(--theme--dark);
}

footer .abi-footer__social-media nav svg {
  min-width: 25px;
  fill: #644b0c;
}

.menu__header {
  align-items: center;
  display: flex;
}

.close {
  align-items: center;
  gap: .25rem;
  font-family: sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  position: absolute;
  top: 2.5rem;
  right: 1rem;
}

.intro__center--mobile {
  display: none;
}

.speaker {
  text-align: center;
  width: 100px;
  flex-direction: column;
  flex-basis: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.speaker-name {
  font-size: var(--font-size--small);
  margin-bottom: 0;
  font-weight: 600;
}

.speaker-firm {
  font-size: var(--font-size--smaller);
}

@media screen and (max-width: 1024px) {
  .intro__center {
    display: none;
  }

  .intro__center--mobile {
    display: block;
  }

  .quad1 {
    min-height: 460px;
  }

  .intro__center {
    margin: 12rem 2rem 2rem;
    top: 0;
    left: 0;
  }

  .agenda > div img {
    width: 130px;
  }

  .speakers p {
    font-size: var(--font-size--default);
  }

  .intro__center .intro__buttons {
    text-align: center;
    flex-direction: column;
    align-items: stretch;
    display: flex;
  }

  .partners__list > li, .sponsors__list > li {
    flex: 0 0 150px;
  }

  .rate {
    padding: 1rem;
  }

  .rate .emphasis .price {
    font-size: 2rem;
  }

  .callout .callout-number {
    font-size: var(--font-size--large);
  }

  .intro__center .intro__buttons .btn {
    margin-bottom: 1rem;
  }

  .more-content > section#hotel article {
    grid-area: 2 / span 2;
  }

  .more-content > section#hotel img {
    grid-column: span 2;
  }
}

.intro__date, .intro__location {
  font-size: var(--font-size--default);
  align-items: center;
  gap: 1rem;
  display: flex;
}

.intro__date svg, .intro__location svg {
  width: 20px;
}

@media screen and (min-width: 768px) {
  .intro__date svg, .intro__location svg {
    width: 30px;
  }
}

#hotel p, #hotel address {
  font-size: 1.75rem;
}

.agenda-nav {
  z-index: 1;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  display: flex;
}

.agenda-nav div {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.agenda-nav h4 {
  margin: 0;
  font-size: 1.75rem;
}

.agenda-nav a {
  color: var(--theme-secondary--dark);
  font-size: 1.75rem;
  display: inline-block;
}

.agenda-nav a:not(:first-of-type) {
  border-left: 4px solid var(--theme-secondary--dark);
  padding-left: 1rem;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  overflow: hidden;
}

#policies h3 {
  font-size: 1.75rem;
}

#policies p {
  font-size: 1.5rem;
}

p {
  max-width: 800px;
}

/*# sourceMappingURL=index.8fc8a475.css.map */
